.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  font-weight: 700;
  line-height: 32px;
  padding-left: 12px;
  margin: 6px 0;
}

.input {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 30px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 7px;
  background: none;
  color: white;
  width: 90%;
  min-height: 50px;
}

.textSave {
  font-size: 14px;
  font-weight: 700;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textareaElementTargetMonth {
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.textareaElementTargetMonth:empty:before{
  content:attr(data-ph);
  color: white;
}