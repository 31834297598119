.buttonServer {
    top: 10px;
    position: absolute;
    background-color: #5F6E67;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    min-width: 150px;
}

.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.mainTablet {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 16px 10px;
    background-color: #297952;
    border-radius: 10px;
    height: 56px;
}

.buttonText {
    color: white;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.buttonLoading {
    padding-inline-end: 10px;
}

.input {
    padding-inline-end: 16px;
    padding-inline-start: 10px;
    border-radius: 10px;
    border: 1px #B3BEB8 solid;
    font-size: 18px;
    color: #000;
    height: 56px;
    width: 280px;
}

.disabledButton {
    background-color: #E3E9E7;
}
