.ReactModal__Content.modal {
    transform: scale(0);
    transition: all 300ms ease-in-out;
}

.ReactModal__Content--after-open.modal {
    transform: scale(1);
    overflow: hidden;
}

.ReactModal__Content--before-close.modal {
    transform: scale(0);
}