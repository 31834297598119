.fade.enter-done {
    opacity: 1;
    transition: opacity 300ms;
}
.fade.enter-active {
    opacity: 0;
}
.fade.exit-done {
    opacity: 0;
    transition: opacity 300ms;
}
.fade.exit-active {
    opacity: 1;
}