.loading {
    display:inline-block;
    clip-path: inset(0 2ch 0 0);
    animation: l 1s steps(4) infinite;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }