.tabbar-open {
    width: 33%;
    transition: width 300ms;
}
.tabbar-close {
    width: 100%;
    transition: width 300ms;
}

.disabled-link {
    pointer-events: none;
}