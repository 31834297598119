.list1.enter-done {
    opacity: 0;
    display: none;
    transition: opacity 300ms;
}
.list1.enter-active {
    opacity: 1;
}
.list1.exit-done {
    opacity: 1;
    transition: opacity 300ms;
}
.list1.exit-active {
    opacity: 0;
    display: none;
}

.arrow.enter-done {
    transform: translateX(0) rotate(0deg);
    transition: transform 300ms;
}
.arrow.enter-active {
    transform: translateX(5px) rotate(180deg);
}
.arrow.exit-done {
    transform: translateX(5px) rotate(180deg);
    transition: transform 300ms;
}
.arrow.exit-active {
    transform: translateX(0) rotate(0deg);
}