@font-face {
  font-family: "Roboto-Thin";
  src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("https://fonts.googleapis.com/css2?family=Roboto&display=swap'");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
}

* {
  font-family: "Roboto-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* TODO reset margins */
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(95, 110, 103, 0.5);
  border-radius: 20px;
}

body {
  margin: 0;
  overflow: hidden;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

button:active {
  border: none;
}

input {
  outline: none;
  border: none;
  background: none;
}

textarea {
  outline: none;
  border: none;
  resize: none;
  background: none;
}

.container {
  height: 100vh;
}

a {
  text-decoration: none;
}

.input[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #297952;
  border-radius: 2px;
  content: "";
  background: #fff;
}

.input[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 0px;
  top: -20px;
  width: 18px;
  height: 22px;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

.input[type="checkbox"]:checked:after {
  background-image: url("./assets/checkbox.svg");
  border: none;
}

.textareaElement[contenteditable="true"] {
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.textareaElement[contenteditable="true"]:empty:before {
  content: attr(data-ph);
  color: #b3beb8;
}

a.autoLink:visited {
  color: white;
}

a.autoLinkMain:visited {
  color: #274335;
}

a.autoLink:active {
  box-shadow: 1px 1px 0px white;
}

a.autoLink {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

a.autoLinkMain {
  color: #274335;
}

.purpleCheckbox[type="checkbox"]:before {
  border: 1px solid #191945;
}

.purpleCheckbox[type="checkbox"]:checked:after {
  background-image: url("./assets/purpleCheckbox.svg");
}
